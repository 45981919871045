@use "ui/colors";

.cycleIcon {
  grid-column: 1;
}

.priceWrapper {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  grid-column: -2;
  margin-top: 4px;
  justify-self: flex-end;

  &:empty {
    display: none;
  }
}
