@use "ui/colors";

.price {
  display: flex;
  align-items: flex-end;
  justify-self: flex-end;
  column-gap: 8px;
  color: colors.$purple-500;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin: 0;

  &:not(:first-child)::before {
    align-self: center;
    background: colors.$gray-700;
    content: "";
    display: block;
    width: 1px;
    height: 15px;
  }

  &__colorless {
    color: inherit;

    &[data-base-price]::after {
      color: inherit;
    }
  }

  &[data-base-price] {
    color: colors.$blue-500;

    &::after {
      color: colors.$gray-700;
      content: attr(data-base-price);
      font-size: 14px;
      font-weight: 400;
      text-decoration: line-through;
    }
  }
}
