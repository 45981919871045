.lowestPrice {
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;
  grid-column: 1 / -1;

  @media (min-width: 992px) {
    font-size: 12px;
  }
}
