@use "ui/colors";

.cover {
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  font-size: 0;
  grid-column: 1 / -1;
}

.image {
  aspect-ratio: 1/1;
  display: block;
  transform: scale(var(--cover-scale, 1));
  transform-origin: center;
  transition: transform 1s ease-in-out;
  width: 100%;
}

.actionButton {
  opacity: var(--action-button-opacity, 0);
}

.progressBar {
  background: colors.$green-500;
  bottom: 0;
  height: 5px;
  left: 0;
  position: absolute;
  width: var(--progress, 0);
  z-index: 3;
}
