@use "sass:color";
@use "ui/colors";

.actionBtn {
  background: colors.$blue-500;
  border: none;
  border-radius: 50%;
  bottom: 8px;
  color: #fff;
  cursor: pointer;
  height: 36px;
  padding: 8px;
  position: absolute;
  right: 8px;
  transition: color 0.25s ease, opacity 0.25s ease, box-shadow 0.25s ease;
  width: 36px;
  z-index: 11;

  @media (min-width: 992px) {
    opacity: 0;
  }

  > svg {
    &:last-child:not(:first-child) {
      display: none;
    }
  }

  &:hover {
    background: colors.$blue-400;
    box-shadow: 0 4px 8px 0 color.adjust(colors.$violet-900, $alpha: -0.8);

    > svg {
      &:first-child:not(:last-child) {
        display: none;
      }

      &:last-child:not(:first-child) {
        display: block;
      }
    }
  }

  &:active {
    background: colors.$blue-500;
    box-shadow: 0 2px 4px 0 color.adjust(colors.$violet-900, $alpha: -0.7);
  }
}
