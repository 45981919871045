@use "ui/colors";

.author {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: 1 / -1;
}

.mainLink {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

.teaser {
  --accent-color: #{colors.$violet-900};
  color: colors.$gray-700;
  align-self: stretch;
  align-content: flex-start;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4px;
  overflow: hidden;
  position: relative;
  isolation: isolate;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      --cover-scale: 1.05;
      --action-button-opacity: 1;
    }
  }

  &__colorless {
    --accent-color: inherit;
    color: inherit;
  }
}

.title {
  color: var(--accent-color);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0 0 4px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  grid-column: 1 / -1;
}
